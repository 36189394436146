import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "content"
}

import { useRoute } from 'vue-router';
import { ref } from 'vue';
import { AppEnv } from '@/utils';
import AutotrimInput from '@/components/autotrim-input/AutotrimInput.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const route = useRoute();
const cookie = ref('');

const redirectUrl = encodeURIComponent(
  (route.query.redirectUrl as string) ?? window.location.origin
);

if (AppEnv.isProduction || AppEnv.isTest) {
  window.open(
    `${process.env.VUE_APP_LOGIN_HOST}sso/sign/login?tenant=lingdong&side=employee&redirect_url=${redirectUrl}&app_id=cli_a5c6ed9bbfe1100c`,
    '_self'
  );
}
const onClickSave = () => {
  document.cookie = cookie.value;
  window.open(redirectUrl, '_self');
};

return (_ctx: any,_cache: any) => {
  const _component_e_button = _resolveComponent("e-button")!

  return (_unref(AppEnv).isDevelopment)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("span", null, "请先去测试环境复制cookie", -1)),
        _createVNode(AutotrimInput, {
          modelValue: cookie.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((cookie).value = $event)),
          style: {"width":"700px","margin-top":"20px"},
          type: "textarea",
          placeholder: "请复制cookie"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_e_button, {
          style: {"margin-top":"20px","width":"50px"},
          onClick: onClickSave
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("保存")
          ])),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})