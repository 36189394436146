import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, watchEffect } from 'vue';
// 如何在组件使用v-model https://cn.vuejs.org/guide/components/v-model.html
// 不能带 v-model; 不能带 .trim
// 其他使用方式等同于e-input， el-input


export default /*@__PURE__*/_defineComponent({
  __name: 'AutotrimInput',
  props: ['modelValue', 'handleInput'],
  emits: ['update:modelValue', 'clear'],
  setup(__props, { emit: __emit }) {

const emits = __emit;
// eslint-disable-next-line vue/require-prop-types
const props = __props;

const values = ref(props.modelValue);

watchEffect(() => {
  values.value = props.modelValue;
});

const inputBlur = () => {
  values.value = values.value.trim();
  if (props.handleInput) {
    values.value = props.handleInput(values.value);
  }
  emits('update:modelValue', values.value);
};

return (_ctx: any,_cache: any) => {
  const _component_e_input = _resolveComponent("e-input")!

  return (_openBlock(), _createBlock(_component_e_input, _mergeProps({
    modelValue: values.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((values).value = $event))
  }, _ctx.$attrs, { onBlur: inputBlur }), _createSlots({ _: 2 }, [
    _renderList(_ctx.$slots, (item, key, index) => {
      return {
        name: key,
        fn: _withCtx(() => [
          _renderSlot(_ctx.$slots, key)
        ])
      }
    })
  ]), 1040, ["modelValue"]))
}
}

})